import * as React from "react"
import { graphql } from "gatsby"
import PostList from "../../components/postlist"
import Layout from "../../components/layout"

const CategoriesPage = ({ data }) => {
  return (
    <Layout pageTitle="All Blog Posts">
      <PostList posts={data.allMdx.nodes} />
    </Layout>
  )
}

export const query = graphql`
  query {
    allMdx(filter: { frontmatter: { cat: { eq: "blog" } } }) {
      nodes {
        frontmatter {
          title
          cat
          date(formatString: "MMMM DD, YYYY")
        }
        id
        slug
        timeToRead
      }
    }
  }
`

export default CategoriesPage
